import { Col } from "../../style"
import { DropDownBox } from "../generic/DropDownBox"

interface DescriptionAreaProps {
    shopItem:ShopItem
}

export const DescriptionArea = ({shopItem}:DescriptionAreaProps)=>{
    return (
        <Col>
         <DropDownBox title={"Description"} content={shopItem.description} bullets={shopItem.descriptionBullets}/>
         <DropDownBox title={"Ingredients"} bullets={shopItem.ingredients}/>
        </Col>
    )
} 