import { Backgrounds, Images } from "../../constants/images";
import styled from "styled-components";
import { Container, Col, Row, Title, Text, Btn, Img, } from "../../style";
import { useDeviceType } from "../../context/DeviceContext";

export const Wellness = () => {
    const { isMobile } = useDeviceType()
 
    return (
        <Section $isMobile={isMobile} id="about">
            <Container>
                <Row>
                    <Col align="end">
                        <Col justify="center" align="start" $maxWidth="600px" gap="10px">
                            <Title $isMobile={isMobile} $fontSize={23} $lineHeight={30} weight={400}>
                            Wellness with

                            </Title>
                            <Title $isMobile={isMobile} $fontSize={40} $lineHeight={50} weight={700}>
                            SlimmyGummy
                            </Title>
                            <Img src={Images.LineLft}/>
                            <Col $maxWidth="550px" margin="0px 0px 20px 0px" gap="20px">
                                <Text>
                                Those who are committed to improving their health with dedication can often see tangible results. However, it’s common to feel overwhelmed by the myriad of routines available, leading to confusion in the pursuit of a healthy lifestyle goals. Advanced nutritional support can provide the essential advantage needed to stay on track and hit those goals. 
                                </Text>
                                <Text>
                                <b>SlimmyGummy</b> introduces a selection of advanced wellness ingredients through expertly crafted, easy-to-use food supplements.
                                </Text>
                                <Text>
                                    Each formulation is optimally fortified, effective and enriched with high quality ingredients that may help support your journey toward optimal health and well-being.
                                </Text>
                            </Col>
                            <Btn width="240px" to={"/#shop"}>place order</Btn>



                        </Col>

                    </Col>
                </Row>

            </Container>

        </Section>
    )
}




const Section = styled.div<{ $isMobile?: boolean}>`
  position: relative;
  width: 100%;
  height: 668px;
  padding:'10px 0px';
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${Backgrounds.Wellness});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; // Ensure the background covers the entire section
    z-index: -1; // Place it behind the content
  }

  /* Optional: Add a background color with some transparency if you want */
  background-color:${props=>props.$isMobile?'rgba(255, 255, 255, 0.6)':'none'} ; // Example: white with 60% opacity: ;
`;
