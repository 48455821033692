import * as React from "react";

import { useState } from "react";
import { useDeviceType } from "../../context/DeviceContext";
import { DataArea, DataProps } from "../data/DataArea";
import {  RequestConfigs } from "./../../api";
import { DataLayoutContainer, CloseButton,SidebarContainer,FilterContainer,Spacer,Head ,DataContainer,Body} from "./index.style";
import { useFormHandling } from "../../hooks/FormHandle";
import { Filter } from "./filter";
import { useFilter } from "../../context/FilterContext";
import { FieldType, TextFieldType } from "../fields";
import Joi from "joi";
import { ItemPreview } from "./itemPreview";


interface DataLayoutProps{
  fields:FieldConfig[]
  PublicCustomFilter?:React.ComponentType<any>;
  Element:React.ComponentType<DataProps>
  HeadElement?:React.ComponentType<any>;
  dataKey:'signals' | 'experts'  | 'courses' | 'customExperts'
  publicPage:string
  privatePage:string
  publicName:string
  privateName:string
  publicRequest:RequestConfig
  privateRequest:RequestConfig
  registerText:any
  registerTitle:string
  notSureText:any
  subscribeText:string
  icon:any
}

const fields:FieldConfig[] = [
  {
    type: FieldType.text,
    name: "search",
    label: "Search",
    joiSchema: Joi.string().allow(null, ''),
    typeOptions:{type:TextFieldType.search}
  },
  {
    type: FieldType.multiSelect,
    name: "type",
    label: "Type",
    joiSchema: Joi.array(),
    typeOptions:{
      options: [
        { name: "KETO", value: 1 },
        { name: "DIET", value: 2 },
      ],
    }
  },
  {
    type: FieldType.multiSelect,
    name: "billingCycleType",
    label: "Method",
    joiSchema: Joi.array(),
    typeOptions:{
      options: [
        { name: "Auto-Refill", value: "RECURRING" },
        { name: "Single-Purchase", value: "ONE_TIME" },
      ],
    }
  },
 
]


export const Shop = () => {

  
  const {isMobile} = useDeviceType()
  const {showFilter,setShowFilter} = useFilter()
  const [page, setPage] = useState<number>(1);
  const { handleInputChange, handleClick, serverRes, formValues,submitted } = useFormHandling({ requestType: RequestConfigs.getShop,params:{campaignId:process.env.REACT_APP_CAMPAIGN_ID} })


  
  React.useEffect(() => {
    
    handleClick()

  }, [formValues,page]);

  const handlePage = (page: number) => {
    setPage(page)
  }


  const data = serverRes?.shopItems


  

  return (
    
    <DataLayoutContainer>
      
      {
        !isMobile &&
        <FilterContainer>
        <Filter submitted={submitted} fields={fields} serverRes={serverRes} handleInputChange={handleInputChange} />
        </FilterContainer>
      }
  

      {
        isMobile && 
        <SidebarContainer $isOpen={showFilter}>
          <CloseButton onClick={()=>setShowFilter(false)}/>
          <Filter submitted={submitted} fields={fields} serverRes={serverRes} handleInputChange={handleInputChange} />
        </SidebarContainer>
        
      }
      <Head>
      
      <DataContainer id="data-container" $isMobile={isMobile}>
      <Body id="body-container">
        <>
        {
        data && data.map((item,index) => (
          <ItemPreview shopItem={item}  key={index}/>

        ))}

        </>
        
      </Body>
  
  </DataContainer>  
        </Head>
    
    </DataLayoutContainer>
  );
};

