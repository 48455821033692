
import styled from "styled-components";
import { Colors } from "../../theme/colors";

import { useDeviceType } from "../../context/DeviceContext";

import React, { useState } from "react";




import { Line } from "../generic/Line"
import { AddToCartButton, ProdcutType } from "../generic/AddToCartButton"
import { BuyNowButton } from "../generic/BuyNowButton";
import { Stars } from "../generic/Stars";
import { Bullets } from "../generic/Bullets";
import { Col, Row, Text } from "../../style";
import { useNavigate } from "react-router-dom";
import { PriceComponent } from "./priceComponent";



interface ItemPreviewProps {
    shopItem:ShopItem
}


export const ItemPreview: React.FC<ItemPreviewProps> = ({shopItem}) => {
    const { isMobile } = useDeviceType()

    const navigate = useNavigate()

    const navigateToProduct = ()=>{
        navigate(`/shop/${shopItem.shopProductId}`,{state:{shopItem}})
    }

    return (
        <>
            <Container>
                <Col>
                    <ExpertIcon onClick={navigateToProduct} $isMobile={isMobile} src={process.env.REACT_APP_API_ENDPOINT+shopItem.baseImage} />
                    <SaveContainer>
                    <Text $fontSize="13px" color={Colors.lightPrimary} background={Colors.secondary} weight={600}>Save {shopItem.currency+(shopItem.retailPrice-shopItem.price).toFixed(2)}</Text>
                    </SaveContainer>
                </Col>
                
                
                <Col>
                    <Text weight={600}>{shopItem.name}</Text>
                    <PriceComponent price={shopItem.price} retailPrice={shopItem.retailPrice} currency={shopItem.currency} />
                    
                </Col>
                <Stars score={shopItem.stars} scoreCount={shopItem.votes}/>
            </Container>

        </>
    );
};



const Container = styled.div`
display: flex;
position: relative;
/* flex-direction: column; */
flex-direction: column;
justify-content:center;
align-items: center;
/* border: 1px solid ${Colors.fieldBorder}; */
background-color: ${Colors.whiteBackground};
width: 250px;
padding: 10px;
border-radius: 8px;
gap:10px;
`

const ExpertIcon = styled.img<{ $isMobile: boolean }>`
box-shadow: 0 0 23px  ${Colors.shadow};
/* border-radius: 50% */
border-radius: 8px;
width: ${(prop => prop.$isMobile ? '100px' : '110px')};
/* height: ${(prop => prop.$isMobile ? '120px' : '140px')}; */
object-fit: cover;
margin:0;
transition: transform 0.5s ease-in-out;
&:hover {
    transform: scale(1.05); /* Enlarge the image on hover */
    cursor: pointer;
  }
`

const SaveContainer = styled.div`
display: flex;
position: absolute;
bottom:10px;
right: 10px;
`
