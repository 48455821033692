import { useLocation } from "react-router-dom"
import { Col, Container, Img, Title, WrappedRow, Row, Btn, Text } from "../../style"
import { ImagesBrowser } from "../generic/ImagesBrowser"
import { Stars } from "../generic/Stars"
import { PriceComponent } from "./priceComponent"
import { Colors } from "../../theme/colors"
import { Icons } from "../../constants/images"
import styled from "styled-components"
import { useEffect, useState } from "react"
import { OfferItem } from "./offerItem"
import { DropDownBox } from "../generic/DropDownBox"
import { DescriptionArea } from "./descriptionArea"
import { useDeviceType } from "../../context/DeviceContext"
import { useCart } from "../../context/CartContext"
import { useFormHandling } from "../../hooks/FormHandle"
import { RequestConfigs } from "../../api"

export const ShopItemComponent = () => {
    const location = useLocation()
    const shopItem = location.state?.shopItem as ShopItem
    const {isMobile} = useDeviceType()

    const [product, setProduct] = useState<ShopItem>(shopItem);

    const [selectedOption, setSelectedOption] = useState('subscribe');
    const [offerIndex, setOfferIndex] = useState(0);
    const [showTerms, setshowTerms] = useState(false);
    const {addToCart,getProductCount} = useCart()

    const { handleInputChange, handleClick, serverRes, formValues,submitted } = useFormHandling({ requestType: RequestConfigs.getShop,params:{campaignId:process.env.REACT_APP_CAMPAIGN_ID} })

    useEffect(() => {
        if(!shopItem){
            handleClick()
        }
        
      }, []);

      useEffect(() => {
        if(serverRes?.shopItems){
            setProduct(serverRes?.shopItems[0])
        }
        
      }, [serverRes]);


    if(!product){
        return (<></>)
    }

    const subscribeOptions = product?.refillOffers?.length > 0 ? product.refillOffers : null
    const oneTimeOptions = product?.oneTimeOffers?.length > 0 ? product.oneTimeOffers : null

    const selectedProducts = selectedOption==="subscribe"?subscribeOptions as ShopOffer[] :oneTimeOptions as ShopOffer[]
    const productCount = selectedProducts?getProductCount(selectedProducts[offerIndex].campaignProductId):0

    const calcSave = () => {
        let result = ''
        if (subscribeOptions?.[0] && oneTimeOptions?.[0]) {
            const saveP = (100 * (oneTimeOptions[0].price - subscribeOptions[0].price) / oneTimeOptions[0].price).toFixed(0)
            result = ` & save %${saveP}`
        }

        return result
    }

 

    return (
        <WrappedRow $gap="50px" justify="flex-start">
            <Col $maxWidth={isMobile?"100%":"45%"} className="image section" justify="flex-start">
                <ImagesBrowser images={product.images} />
                {
                    !isMobile &&
                    <DescriptionArea shopItem={product}/>
                }
               
            </Col>
         
            <Col $maxWidth={isMobile?"100%":"45%"} justify="flex-start" align="start" padding="10px" gap="25px" className="details section">
                <Col justify="flex-start" align="start">
                    <Title $isMobile={isMobile} $fontSize={35}>{product.name}</Title>
                    <Stars score={product.stars} scoreCount={product.votes} size={1.2} />
                </Col>

                <Row justify="flex-start" gap="10px">
                    <Col align="start" $maxWidth="30px">
                        <Img src={Icons.DeliveryIcon} width={25} />
                    </Col>
                    <Col align="start">
                        <Text weight={600}>Free Delivery</Text>
                    </Col>

                </Row>
                <PriceComponent price={selectedProducts[offerIndex].price} retailPrice={selectedProducts[offerIndex].retailPrice} currency={product.currency} align="flex-start" />

                <Col justify="flex-start" align="start">
                    <Option selected={selectedOption === 'subscribe'}>
                        <RadioInput
                            type="radio"
                            name="purchaseOption"
                            value="subscribe"
                            checked={selectedOption === 'subscribe'}
                            onChange={() => setSelectedOption('subscribe')}
                        />
                        <LabelText selected={selectedOption === 'subscribe'}>
                            Subscribe{calcSave()}
                        </LabelText>
                        <Price>{product.currency + subscribeOptions?.[offerIndex].price}</Price>

                    </Option>

                    <Option selected={selectedOption === 'one-time'}>
                        <RadioInput
                            type="radio"
                            name="purchaseOption"
                            value="one-time"
                            checked={selectedOption === 'one-time'}
                            onChange={() => setSelectedOption('one-time')}
                        />
                        <LabelText selected={selectedOption === 'one-time'}>
                            One-time purchase
                        </LabelText>
                        <Price>{product.currency + oneTimeOptions?.[offerIndex].price}</Price>
                    </Option>
                    <Col>
                    
                    </Col>
                    <Row justify="flex-start" gap="10px" margin="15px 0px 0px 0px" onMouseEnter={() => setshowTerms(true)} onMouseLeave={() => setshowTerms(false)}>
                       

                        <Col align="start" $maxWidth="30px">
                            <Img src={Icons.Renewal} width={25} />
                        </Col>
                        <Col align="start">
                            <Text weight={500}>Subscription info</Text>
                        </Col>

                    </Row>
                    {showTerms &&
                            <TermsBox>
                                <Col>
                                    <Text $fontSize="12px" $lineHeight="13px" weight={600}>How subscriptions work</Text>
                                    <Text $fontSize="12px" $lineHeight="13px">Products are automatically delivered on your schedule. No obligation, modify or cancel your subscription anytime.</Text>
                                </Col>
                            </TermsBox>
                        }
                </Col>
              
                <Col gap="10px" justify="flex-start" align="start">
                    <Btn onClick={()=>addToCart(selectedProducts[offerIndex].campaignProductId)} to={"/cart"} width="90%">BUY NOW</Btn>
                    <Btn  onClick={(e) => {
                                            e.preventDefault(); // Prevent any navigation
                                            addToCart(selectedProducts[offerIndex].campaignProductId);
                                        }} to="#" width="90%" $backgroundColor1={Colors.secondary}>ADD TO CART<CartCount show={productCount>0}>{productCount}</CartCount></Btn>

                </Col>

                <Col justify="flex-start" align="start" gap="10px">
                        <Text>
                            Quantity
                        </Text>

                        {selectedProducts.map((offer, index) => (
                          <OfferItem onSelect={setOfferIndex} currentIndex={offerIndex} offerIndex={index} offerItem={offer} image={product.baseImage} currency={product.currency} key={index}/>
                        ))}

                      
                </Col>





            </Col>

            {
                    isMobile &&
                    <DescriptionArea shopItem={product}/>
                }
            
            {
                product.brochure &&
                <Col margin="50px 0px">
                <Img width="90%" src={process.env.REACT_APP_API_ENDPOINT + product.brochure}/>
                </Col>
            }
           
          
        </WrappedRow>
    )

}




const Option = styled.label<{ selected: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px;
  margin: 5px 0;
  border-radius: 8px;
  width: 90%;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? '#f0f0f0' : '#ffffff')};
  box-shadow: ${({ selected }) => (selected ? '0 0 4px rgba(0,0,0,0.2)' : 'none')};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f9f9f9;
  }
`;

const RadioInput = styled.input`
  margin-right: 10px;
  accent-color: #4a90e2; /* Blue for selected */
`;

const LabelText = styled.span<{ selected: boolean }>`
  font-size: 16px;
  font-weight: ${({ selected }) => (selected ? '600' : '400')};
`;

const Price = styled.span`
  margin-left: auto;
  font-weight: 500;
`;

const CartCount = styled.span<{show:Boolean}>`
background: ${Colors.whiteBackground};
color: ${Colors.secondary};
margin: 0px 10px;
width: 25px;
height: 25px;
border-radius: 100%;
display: ${({ show }) => (show ? 'inline-block' : 'none')};
`

const TermsBox = styled.div`
display: flex;
position: absolute;
bottom:35px;
padding: 10px;
left: 0;
width: 90%;
z-index: 150;
background-color:${Colors.whiteBackground};
border: 1px solid ${Colors.fieldBorder};

`