
import { useLocation } from "react-router-dom";
import { useDeviceType } from "../../context/DeviceContext";

import { FilterForm } from "./index.style";
import { useEffect } from "react";
import { FormComponent } from "../generic/Form";


interface FilterProps{
    fields:any
    serverRes:any
    submitted:boolean
    handleInputChange:any
}

export const Filter = ({fields,serverRes,handleInputChange,submitted}:FilterProps) => {
  const {isMobile} = useDeviceType()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(queryParams.entries());
  useEffect(() => {
    if(fields){
      for(let i in fields){
        if(params[fields[i].name]){
          fields[i].defaultValue=params[fields[i].name]
        }

      }
    } 
  }, [fields]);

  return (
    <FilterForm $isMobile={isMobile}>
    <FormComponent fields={fields} serverRes={serverRes} submitted={submitted} title="Filter" onChange={handleInputChange} showMessageBox={false}></FormComponent>
  </FilterForm>
  );
};






