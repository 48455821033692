import { useRef, useState } from "react"
import { Col, Img } from "../../style"
import { Colors } from "../../theme/colors"
import styled from "styled-components"
import { useDeviceType } from "../../context/DeviceContext"

interface ImagesBrowserProps{
    images:string[]
}
export const ImagesBrowser = ({images}:ImagesBrowserProps)=>{
    const [currentImage,setCurrentImage] = useState<string>(images[0])
    const scrollRef = useRef<HTMLDivElement>(null);

    const handleScroll = (direction:string) => {
        if (scrollRef.current) {
            const { scrollLeft, clientWidth } = scrollRef.current;
            const scrollAmount = clientWidth / 2; // Scrolls half the width of the container
            const newScrollPosition = direction === 'left' ? scrollLeft - scrollAmount : scrollLeft + scrollAmount;
            scrollRef.current.scrollTo({ left: newScrollPosition, behavior: 'smooth' });
        }
    };
    const {isMobile} = useDeviceType()


    return (
        <Col>
        <MainImgBox padding="10px 125px" height={isMobile?"300px":"600px"} >
            <Img width={"100%"} src={process.env.REACT_APP_API_ENDPOINT+currentImage}/>
        </MainImgBox>
        <ScrollContainerWithButtons>
        <ScrollButton onClick={() => handleScroll('left')}>◀</ScrollButton>
        <ScrollableContainer ref={scrollRef}>
            
          <BoxSection>
            
            {images?.map((image, index) => (
            <SmallBox>    
                <Img 
                src={process.env.REACT_APP_API_ENDPOINT+image} 
                onClick={()=>setCurrentImage(images[index])}
                width={"100%"}
                />
            </SmallBox>            
            ))}

            
          </BoxSection>
          
        </ScrollableContainer>
        <ScrollButton onClick={() => handleScroll('right')}>▶</ScrollButton>
      </ScrollContainerWithButtons>
        </Col>
       
    )

}

const ScrollContainerWithButtons = styled.div`
  position: relative;
  width: 100%; // Ensures the container fills the parent
  display: flex;
  justify-content: space-between; // Spreads the child elements across the container
  align-items: center;
  margin-top: 10px;
`;

const ScrollButton = styled.button`
  min-width: 40px; // Ensure buttons have a fixed width
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${Colors.whiteBackground};
  color: ${Colors.text};
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 1000;
  font-size: 20px;
  &:hover {
    color: ${Colors.primary};
  }

  &:first-child {
    left: 0; // Left button at the start
  }

  &:last-child {
    right: 0; // Right button at the end
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const ScrollableContainer = styled.div`
  overflow-x: auto; 
  scrollbar-width: none; 
  -ms-overflow-style: none; 
  flex-grow: 1; // Takes up available space between buttons
  padding: 0 50px; // Adjust this padding based on the size of your buttons
  display: flex; /* Ensure the container's children are displayed in a row */
  align-items: center; /* Center align the children vertically */
  justify-content: center;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const BoxSection = styled.div`
  display: flex;
  /* padding-bottom: 20px; */
  flex: 0 0 auto; 
  gap: 5px 10px;
  margin-right:none ;
`;

const MainImgBox = styled.div<{padding?:string ,background?:string, gap?:string, align?: 'center' | 'start' | 'end' ,justify?:'center' | 'flex-start' | 'flex-end',margin?:string , $maxWidth?:string, height?:string ,border?:string }>`
  display: flex;
  position: relative;
  flex-direction: column;
  margin:${props => props.margin || '0px'};
  padding:${props => props.padding || '0px'};
  justify-content:${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  gap:  ${props => props.gap || '0px'};
  width: 100%;
  max-width: ${props => props.$maxWidth !== undefined ? props.$maxWidth: 'none' };
  background: ${props=>props.background|| 'transparent'};
  box-sizing: border-box;
  z-index: 1;
  height:${props=>props.height|| 'auto'};
  border:${props=>props.border|| 'none'};
  border-radius: 10px;
`;

const SmallBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  border: 1px solid ${Colors.fieldBorder};
  margin-right:none ;
  border-radius: 5px;
  width: 50px;
  cursor: pointer;
`;

